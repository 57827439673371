<template>
    <div class="term-detail-page">
        <full-loading ref="fullLoading"></full-loading>
        <page-title :showBack="false" :title="info.name||''" :title-edit="$checkPermission('memory:termStoreRouter:rename')" @saveTitle="saveTitleEvent" style="border: none;" v-if="info">
            <template slot="info">
                <div class="more-info">
                    <el-popover trigger="hover">
                        <el-form label-width="80px" size="mini" class="more-info-popover">
                            <el-form-item :label="$t('asset.term_detail_page_more_info_form_item_label_1')" v-if="LANGUAGE_DICT">
                                {{LANGUAGE_DICT[info.sourceLang].name}} <i class="el-icon-arrow-right"></i> {{LANGUAGE_DICT[info.targetLang].name}}
                            </el-form-item>
                            <el-form-item :label="$t('asset.term_detail_page_more_info_form_item_label_2')">{{info.nums}} {{$t('asset.term_detail_page_more_info_form_item_label_2_1')}}</el-form-item>
                            <el-form-item :label="$t('asset.term_detail_page_more_info_form_item_label_3')">{{info.createTime}}</el-form-item>
                        </el-form>
                        <svg-icon name="ib-info" class-name="color-555" slot="reference"></svg-icon>
                    </el-popover>
                </div>
            </template>
            <template slot="tools">
                <div style="width:160px;">
                    <el-input :placeholder="$t('asset.term_detail_page_search_input_placeholder')" size="small" round suffix-icon="el-icon-search" maxlength="100" clearable 
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
                <el-button size="small" :disabled="tableSelection.length < 1" plain round icon="el-icon-delete" v-permission="`memory:termStoreRouter:delete`" @click="e=>deleteBtnEvent(e)" style="margin-left:10px;">{{$t('asset.term_detail_page_search_btn_label_1')}}</el-button>
                <el-button type="primary" size="small" round icon="el-icon-upload2" v-permission="`memory:termStore:detailRouter:upload`" @click="upload.show = true" style="margin-left:10px;">{{$t('asset.term_detail_page_search_btn_label_2')}}</el-button>
                <el-dropdown  @command="downloadFileEvent">
                    <el-button type="primary" round size="small" style="margin-left:10px;">
                        {{$t('asset.term_detail_page_search_btn_label_3')}} <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="excel" v-permission="`memory:termStore:detailRouter:downloadExcel`">{{$t('asset.term_detail_page_search_btn_label_4')}}</el-dropdown-item>
                        <el-dropdown-item command="tbx" v-permission="`memory:termStore:detailRouterr:downloadTBX`">{{$t('asset.term_detail_page_search_btn_label_5')}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                
            </template>
        </page-title>
        <el-table :data="list" ref="tableList" :cell-class-name="tableRowClassName" @selection-change="handleTableSelectionChange" @row-click="handleTableRowClick" style="width: 100%" class="table-list">
            <el-table-column type="selection" :selectable="(row,index)=>{return index !== 0}"></el-table-column>
            <el-table-column width="80" type="index" :label="$t('asset.term_detail_page_table_colum_label_1')" :index="tableIndexMethod"></el-table-column>
            <el-table-column :label="$t('asset.term_detail_page_table_colum_label_2', {value: info && LANGUAGE_DICT && LANGUAGE_DICT[info.sourceLang] && LANGUAGE_DICT[info.sourceLang].name})">
                <template slot-scope="scope">
                    <div class="flex flex-align-center" :lang="info && info.sourceLang" :data-id="scope.row.termDetailId">
                        <template v-if="scope.row.termDetailId">
                            <el-tooltip placement="top">
                                <div class="line-height-1.5" slot="content">
                                    <div>{{$t('asset.term_detail_page_table_colum_tips_1')}}{{scope.row.createTime}}</div>
                                    <div>{{$t('asset.term_detail_page_table_colum_tips_2')}}{{scope.row.createBy}}</div>
                                    <div>{{$t('asset.term_detail_page_table_colum_tips_3')}}{{scope.row.updateTime}}</div>
                                    <div>{{$t('asset.term_detail_page_table_colum_tips_4')}}{{scope.row.updateBy}}</div>
                                </div>
                                <svg-icon name="ib-info" class="margin-r-5 color-666666 fonts-16 flex-shrink-0"></svg-icon>
                            </el-tooltip>
                        </template>
                        <template v-if="scope.row.edit === true">
                            <el-input size="small" maxlength="500" show-word-limit v-model="scope.row.source" :placeholder="$t('asset.term_detail_page_table_colum_input_placeholder_1')" @keydown.native.enter="saveEditEvent(scope)"></el-input>
                        </template>
                        <template v-else>
                            <template v-if="scope.row.termDetailId">{{scope.row.source}}</template>
                            <template v-else>
                                <el-input size="small" maxlength="500" show-word-limit v-model="newSourceValue" :placeholder="$t('asset.term_detail_page_table_colum_input_placeholder_1')" @keydown.native.enter="saveAddEvent"></el-input>
                            </template>
                        </template>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('asset.term_detail_page_table_colum_label_3', {value: info && LANGUAGE_DICT && LANGUAGE_DICT[info.targetLang] && LANGUAGE_DICT[info.targetLang].name})">
                <template slot-scope="scope">
                    <div class="flex flex-align-center" :lang="info && info.targetLang">
                        <template v-if="scope.row.termDetailId">
                            <el-tooltip placement="top">
                                <div class="line-height-1.5" slot="content">
                                    <div>{{$t('asset.term_detail_page_table_colum_tips_1')}}{{scope.row.createTime}}</div>
                                    <div>{{$t('asset.term_detail_page_table_colum_tips_2')}}{{scope.row.createBy}}</div>
                                    <div>{{$t('asset.term_detail_page_table_colum_tips_3')}}{{scope.row.updateTime}}</div>
                                    <div>{{$t('asset.term_detail_page_table_colum_tips_4')}}{{scope.row.updateBy}}</div>
                                </div>
                                <svg-icon name="ib-info" class="margin-r-5 color-666666 fonts-16 flex-shrink-0"></svg-icon>
                            </el-tooltip>
                        </template>
                        <template v-if="scope.row.edit === true">
                            <el-input size="small" maxlength="500" show-word-limit v-model="scope.row.target" :placeholder="$t('asset.term_detail_page_table_colum_input_placeholder_2')" @keydown.native.enter="saveEditEvent(scope)"></el-input>
                        </template>
                        <template v-else>
                            <template v-if="scope.row.termDetailId">{{scope.row.target}}</template>
                            <template v-else>
                                <el-input size="small" maxlength="500" show-word-limit v-model="newTargetValue" :placeholder="$t('asset.term_detail_page_table_colum_input_placeholder_2')" @keydown.native.enter="saveAddEvent"></el-input>
                            </template>
                        </template>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('asset.term_detail_page_table_colum_label_4')">
                <template slot-scope="scope">
                    <div>
                        <template v-if="scope.row.edit === true">
                            <el-input size="small" maxlength="100" show-word-limit v-model="scope.row.description" :placeholder="$t('asset.term_detail_page_table_colum_input_placeholder_3')" @keydown.native.enter="saveEditEvent(scope)"></el-input>
                        </template>
                        <template v-else>
                            <template v-if="scope.row.termDetailId">{{scope.row.description}}</template>
                            <template v-else>
                                <el-input size="small" maxlength="100" show-word-limit v-model="newRemarkValue" :placeholder="$t('asset.term_detail_page_table_colum_input_placeholder_3')" @keydown.native.enter="saveAddEvent"></el-input>
                            </template>
                        </template>
                    </div>
                </template>
            </el-table-column>
            <el-table-column width="120" :label="$t('asset.term_detail_page_table_colum_label_5')">
                 <template slot-scope="scope">
                     <template v-if="scope.row.edit === true">
                         <el-tooltip :content="$t('asset.term_detail_page_table_colum_btn_tips_1')"><span class="table-col-icon" @click="saveEditEvent(scope)"><svg-icon name="ib-check-o"></svg-icon></span></el-tooltip>
                         <el-tooltip :content="$t('asset.term_detail_page_table_colum_btn_tips_2')"><span class="table-col-icon" @click="e=>deleteBtnEvent(e,scope.row)"><svg-icon name="ib-trash-o"></svg-icon></span></el-tooltip>
                     </template>
                     <template v-else>
                         <template v-if="scope.row.termDetailId">
                            <el-tooltip :content="$t('asset.term_detail_page_table_colum_btn_tips_3')"><span class="table-col-icon" @click="editBtnEvent(scope)"><svg-icon name="ib-edit"></svg-icon></span></el-tooltip>
                            <el-tooltip :content="$t('asset.term_detail_page_table_colum_btn_tips_2')"><span class="table-col-icon" @click="e=>deleteBtnEvent(e,scope.row)"><svg-icon name="ib-trash-o"></svg-icon></span></el-tooltip>
                         </template>
                         <template v-else>
                             <el-tooltip :content="$t('asset.term_detail_page_table_colum_btn_tips_1')"><span class="table-col-icon" @click="saveAddEvent"><svg-icon name="ib-check-o"></svg-icon></span></el-tooltip>
                         </template>
                     </template>
                     
                 </template>
            </el-table-column>
        </el-table>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize">
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :current-page="pageNumber"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
        <el-dialog :title="$t('asset.term_detail_page_dialog_title_1')" :visible.sync="upload.show" destroy-on-close @close="uploadCloseEvent" :width="'520px'" top="20px">
            <div class="fonts-12 margin-b-16 line-height-0">
                {{$t('asset.term_detail_page_dialog_message_1', {value: 'xlsx/xls/tbx/sdltb'})}}
                <el-button type="text" class="color-link padding-0" :disabled="upload.downloadLoading" @click="downloadTemplateEvent">excel</el-button>
            </div>
            <upload-oss ref="appendUploadFileDom" :multiple="false" :accept="upload.accept" :params="{}"></upload-oss>
            <div slot="footer" class="text-center">
                <el-button round plain size="small" @click="upload.show = false">{{$t('asset.term_detail_page_dialog_1_btn_1')}}</el-button>
                <el-button round type="primary" size="small" :loading="upload.loading" @click="submitAppendFile">{{$t('asset.term_detail_page_dialog_1_btn_2')}}</el-button>
            </div>
        </el-dialog>
        <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="deleteConfirmEvent"
            @cancelEvent="deleteCancelEvent"></alert-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import $ from 'jquery';
import FullLoading from '@/components/FullLoading';
import fileDownload from 'js-file-download';
import { jargonApi, termApi, commonApi } from '@/utils/api';
import config from '@/utils/config';
import PageTitle from '@/components/PageTitle';
import AlertDialog from '@/components/AlertDialog';
import UploadOss from '@/components/UploadOss';
export default {
    components:{PageTitle, AlertDialog, FullLoading, UploadOss},
    data(){
        return {
            info: null,
            searchForm:{
                kw:'',
                sort:'1'
            },
            searchTimer:null,
            list:[{termDetailId:null,source:'',target:'',edit:false}],
            tableSelection:[],
            alertInfo:{
                message: this.$t('asset.term_detail_page_alertInfo_message'),
                type:'confirm', //alert ,confirm
                buttonName: this.$t('asset.term_detail_page_alertInfo_buttonName'),
                buttonType:'danger',
            },
            pageSize:20,
            pageNumber:1,
            total:0,
            
            newSourceValue:'',
            newTargetValue:'',
            newRemarkValue: '',
            // editSourceValue:'',
            // editTargetValue:'',

            uploadBtnLoading:false,
            upload:{
                show: false,
                loading: false,
                downloadLoading: false,
                accept:'.xlsx,.xls,.tbx,.sdltb',
            },
        }
    },
    computed: {
        ...mapState({
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        })
    },
    created(){
        // this.info = this.$ls.get('termDbInfo');
        
    },
    mounted(){
        this.initPage();
    },
    methods:{
        initPage(loading){
            console.log(this.$route.query)
            let url = this.$ajax.format(termApi.termsDetail, {id:this.$route.query.id});
            if(this.$route.query.dId) {
                url = this.$ajax.format(termApi.termsDetailLocation, {id: this.$route.query.id, dId: this.$route.query.dId});
            }
            url = `${url}?pageSize=${this.pageSize}&pageNumber=${this.pageNumber}`;
            if(this.searchForm.kw){
                url = url + `&text=${encodeURI(this.searchForm.kw)}`;
            }
            if(loading !== false){
                this.$refs.fullLoading.setShow();
            }
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.list = [].concat([{termDetailId:null,source:'',target:'',description:'',edit:false}],res.data.pager.records);
                    this.total = res.data.pager.total;
                    this.info = res.data.termInfo;
                    this.pageNumber = res.data.pager.pageNumber;
                    if(this.$route.query.dId) {
                        this.$nextTick(()=>{
                            this.$scrollTo($(`[data-id="${this.$route.query.dId}"]`)[0], 30, {container: '.el-main', offset: 10,});
                        });
                    }
                }
            }).finally(()=>{
                if(loading !== false){
                    this.$refs.fullLoading.setHide();
                }
            })

        },
        inputSearchEvent(value){
            let self = this;
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = setTimeout(()=>{
                this.pageNumber = 1;
                self.initPage();
            },500);
        },
        handleTableSelectionChange(val){
            console.log(val);
            this.tableSelection = val;
        },
        handleTableRowClick(row, column, event){
            console.log(row,column,event)
            if (row.termDetailId && !row.edit) {
                let index = -1;
                for (let i = 0; i < this.list.length; i++) {
                    const element = this.list[i];
                    if (element.termDetailId === row.termDetailId) {
                        index = i;
                        break;
                    }
                }
                if (index > 0) {
                    this.list.splice(index,1,{...this.list[index],edit:true});
                    setTimeout(() => {
                        $(event.target).find('input').focus();
                    }, 100);
                }
            }
        },
        tableIndexMethod(index) {
            if(index === 0) return '–'
            return index;
        },
        handleCurrentChange(num){
            if(this.$route.query.dId) {
                this.$router.replace({path: this.$route.path, query: {id: this.$route.query.id}})
            }
            this.pageNumber = num;
            this.initPage();
        },
        editBtnEvent(scope){
            let row=scope.row;
            this.list.splice(scope.$index,1,{...this.list[scope.$index],edit:true});
        },
        saveEditEvent(scope){
            let row = scope.row;
            if(!row.source){
                this.$message.error(this.$t('asset.term_detail_page_validation_message_1'));
                return;
            }
            if(!row.target){
                this.$message.error(this.$t('asset.term_detail_page_validation_message_2'));
                return;
            }
            let url = termApi.saveEditTerm;
            let postData = {
                source:row.source,
                target:row.target,
                description:row.description,
                termDetailId:row.termDetailId
            }
            this.$ajax.put(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('asset.term_detail_page_validation_message_3'));
                    this.initPage(false);
                }
            })
        },
        saveAddEvent(){
            if(!this.newSourceValue){
                this.$message.error(this.$t('asset.term_detail_page_validation_message_4'));
                return;
            }
            if(!this.newTargetValue){
                this.$message.error(this.$t('asset.term_detail_page_validation_message_5'));
                return;
            }
            let url = termApi.submitAddTerm;
            let postData={
                termId: this.$route.query.id,
                source:this.newSourceValue,
                target:this.newTargetValue,
                description:this.newRemarkValue,
            }
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('asset.term_detail_page_validation_message_6'));
                    this.newSourceValue = '';
                    this.newTargetValue = '';
                    this.newRemarkValue = '';
                    this.pageNumber = 1;
                    this.initPage(false);
                }
            })
        },
        saveTitleEvent(value){
            if(!value){
                this.$message.error(this.$t('asset.term_detail_page_validation_message_7'));
                return;
            }
            let url = termApi.termDB;
            let postData={
                name:value,
                termId:this.info.termId
            };
            this.$ajax.put(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('asset.term_detail_page_validation_message_8'));
                    this.info.name = value;
                    this.$ls.set('termDbInfo',this.info);
                }
            }).finally(()=>{

            })
        },
        cancelBtnEvent(scope){
            this.list.splice(scope.$index, 1, {...this.list[scope.$index], edit:false})
        },
        downloadFileEvent(type){
            let url = commonApi.batchCreateDownloadTask;
            this.$ajax.post(url, {
                metadataIdList: [this.info.termId], 
                title: this.info.name,
                metadataType: 'ASSET_TERM',
                moreOptions: {
                    exportFileType: type,
                }
            }).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true}}));
                }
            }).finally(()=>{
                
            })
        },
        deleteBtnEvent(e,item){
            if(item){
                this.tableSelection = [item];
            }
            this.$refs.alertDialogDom.setShow(true);
        },
        deleteConfirmEvent(){
            let url = termApi.batchDeleteTerm;
            let postData=[];
            this.tableSelection.map(item=>{
                postData.push(item.termDetailId);
            })
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('asset.term_detail_page_validation_message_9'));
                    this.tableSelection = [];
                    if(this.list.length === 2){
                        this.pageNumber = this.pageNumber > 1 ? this.pageNumber - 1 : 1;
                    }
                    this.initPage(false);
                }
            })
            
        },
        deleteCancelEvent(){
            this.tableSelection = [];
            this.$refs.tableList.clearSelection();
        },

        downloadTemplateEvent(){
            let downMsg = this.$message({
                message: this.$t('asset.term_detail_page_downloadTemplate_message'),
                type: 'info',
                iconClass: 'el-icon-loading',
                duration: 0,
            });
            this.upload.downloadLoading = true;
            this.$ajax.get(jargonApi.downloadTemplate).then(res=>{
                if(res.data){
                    window.open(res.data);
                }else {
                    this.$message.error(this.$t('asset.term_detail_page_validation_message_10'));
                }
            }).finally(()=>{
                this.upload.downloadLoading = false;
                downMsg.close();
            })
        },

        uploadCloseEvent(){
            this.upload.loading = false;
        },
        /**
         * 追加文件
         */
        submitAppendFile(){
            let url = termApi.appendTermFile;
            let docs = this.$refs.appendUploadFileDom.getOssFiles();
            if(docs.length === 0){
                this.$message.error(this.$t('asset.term_detail_page_validation_message_11'));
                return false;
            }
            let postData = {
                id: this.$route.query.id,
                docs,
            }
            this.upload.loading = true;
            this.$ajax.post(url, postData).then(res => {
                if (res.status === 200){
                    this.upload.show = false;
                    this.$message.success(this.$t('asset.term_detail_page_validation_message_12'));
                    this.pageNumber = 1;
                    this.initPage();
                }
            }).finally(()=>{
                this.upload.loading = false;
            })
        },
        tableRowClassName({row, rowIndex}) {
            if(this.$route.query.dId && row.termDetailId === this.$route.query.dId) {
                return 'active-row';
            }
            return '';
        },
    }
}
</script>
<style lang="scss" scoped>
.term-detail-page{
    // padding: 20px;
    .more-info{
        cursor: pointer;
        svg{
            font-size: 20px;
        }
    }
    .table-col-icon{
        margin-right: 15px;
        cursor: pointer;
        font-size: 17px;
        color: #000000;
    }
}
</style>
<style lang="scss">
.table-list{
    &.el-table{
        // background-color:inherit;
        .active-row {
            background-color: #E5F2E6 !important;
        }
    }
    &.el-table th, &.el-table td{
        padding: 10px 0;
    }
    &.el-table tr{
        background-color:inherit;
    }
}
</style>
